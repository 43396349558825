<template>
  <div class="contents cover" id="service">
    <h1 title="サービス">SERVICE</h1>

    <div class="intro">
      <h2>主にイベント・展示等で使う<br class="no-sp">ゲームコンテンツの開発をしています</h2>
      <p>ゲーム開発の技術を駆使して、アプリ・Webコンテンツはもちろん、<br class="no-sp">
        赤外線センサー（RealSenseやKinect、LiDAR等）を使ったインタラクティブなコンテンツを制作することが得意です。<br>
        主な取扱言語：C#、C++、Python、JavaScript、PHP、html+CSS、Javaなど…
      </p>
    </div>

    <section class="game">
      <h2 data-aos="fade-left" data-aos-offset="200" title="ゲーム開発">GAME</h2>

      <div data-aos="fade-right" data-aos-offset="200" class="content">
        <div class="head">
          <div class="image">
            <img src="../assets/service_game.png" alt="">
          </div>
          <div class="body">
            <h3>プラットフォームに囚われない<br>コンテンツ開発</h3>
            <p>スマートフォンやタブレットのゲーム制作はもちろん、WebGL、VR等、様々なシーンやユーザーに合わせて最適な端末でのコンテンツを提供します。<br>
              主な取扱ツール等：Unity(Windows, iOS, Android, WebGL)、PlayCanvas（WebGL ※スマートフォン対応）、VisualStudio、WPF、PyCharm、ArduinoIDE、MetaShape（メモリ128GBのマシン完備）、WordPress、Vue.js
            </p>
            <ul class="feature">
              <li data-aos="fade-up" data-aos-delay="100"><img src="../assets/ico_iphone.svg" alt="">iPhone</li>
              <li data-aos="fade-up" data-aos-delay="200"><img src="../assets/ico_android.svg" alt="">Android</li>
              <li data-aos="fade-up" data-aos-delay="300"><img src="../assets/ico_windows.svg" alt="">Windows</li>
              <li data-aos="fade-up" data-aos-delay="400"><img src="../assets/ico_mac.svg" alt="">Mac</li>
              <li data-aos="fade-up" data-aos-delay="500"><img src="../assets/ico_linux.svg" alt="">Linux</li>
            </ul>
          </div>
        </div>

      </div>
    </section>

    <section class="virtual">
      <h2 data-aos="fade-left" data-aos-offset="200" title="バーチャルコンテンツ">VIRTUAL</h2>
      <div data-aos="fade-right" data-aos-offset="200" class="content">
        <div class="head r">
          <div class="image">
            <img src="../assets/service_virtual.png" alt="">
          </div>
          <div class="body">
            <h3>現実では体験・実現できない、<br>まだ見ぬ世界や未来の創造</h3>
            <p>仮想世界では重力や空間・常識等に一切の縛りがなく、想像するもの全てを具現化することが可能です。<br
                class="no-sp">仮想空間のVRだけではなく、現実を拡張したARやMRなどの制作も弊社では可能です。<br>
              主な対応デバイス：OculusQuest2、Vive、Hololens、WindowsMRデバイス、スマフォ（アプリ、WEB）</p>
            <ul>
              <li data-aos="fade-up" data-aos-delay="100"><img src="../assets/ico_linux.svg" alt="">VR</li>
              <li data-aos="fade-up" data-aos-delay="200"><img src="../assets/ico_linux.svg" alt="">MR</li>
              <li data-aos="fade-up" data-aos-delay="300"><img src="../assets/ico_linux.svg" alt="">AR</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="interactive right">
      <h2 data-aos="fade-left" data-aos-offset="200" title="インタラクティブ">INTERACTIVE</h2>
      <div data-aos="fade-right" data-aos-offset="200" class="content">
        <div class="head">
          <div class="image">
            <img src="../assets/service_interactive.png" alt="">
          </div>
          <div class="body">
            <h3>五感に感じる新しい体験と<br>生まれる新感覚の創造</h3>
            <p>見る・聞く・触れる・感じる・共有するから生まれる経験したことの無い感覚や体験を創造します。デジタルの世界と現実の世界をセンサーやプロジェクター等を用いることによって、空間からの体験を生み出します</p>
            <ul>
              <li data-aos="fade-up" data-aos-delay="100"><img src="../assets/ico_event.svg" alt="">イベント・展示</li>
              <li data-aos="fade-up" data-aos-delay="200"><img src="../assets/ico_iot.svg" alt="">IOT</li>
              <li data-aos="fade-up" data-aos-delay="300"><img src="../assets/ico_heartbeat.svg" alt="">アミューズメント</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="network">
      <h2 data-aos="fade-left" data-aos-offset="200" title="ネットワーク / ウェブ">NETWORK/WEB</h2>
      <div data-aos="fade-right" data-aos-offset="200" class="content">
        <div class="head r">
          <div class="image">
            <img src="../assets/service_web.png" alt="">
          </div>
          <div class="body">
            <h3>ネットワークの活用と、空間を超えたユーザー体験を提供</h3>
            <p>インターネット等のネットワークを介してエリアや時間を超えた体験や共有を提供します。またネット上の膨大なデータリソースやAPIの活用によってより豊かなコンテンツをご提供できます。</p>
            <ul>
              <li data-aos="fade-up" data-aos-delay="100"><img src="../assets/ico_linux.svg" alt="">WEB 3D</li>
              <li data-aos="fade-up" data-aos-delay="200"><img src="../assets/ico_linux.svg" alt="">参加型/体験型</li>
              <li data-aos="fade-up" data-aos-delay="300"><img src="../assets/ico_linux.svg" alt="">プロモーション</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <ToTop />
    <ToContact />

  </div>
</template>

<script>

export default {
  metaInfo: {
    title: 'サービス',
    bodyAttrs: {
      id: 'pg-service'
    },
    meta: [
      { name: 'description', content: 'お客様のニーズに合わせてUnityをベースにしたプラットフォームや空間に縛られないゲーム・コンテンツ制作を得意としています。' }
    ],
    link: [
      { rel: 'canonical', href: 'https://sorashiro.com/service/' }
    ]
  },
  mounted: function () {
    this.$nextTick(function () {
      this.initGsap(this.$isMobile())
    })
  },
  beforeDestroy: function () {
    this.removeGsap()
  }
}
</script>
<style lang="scss" scoped>
    .contents{

      h3{
        margin: 0 0 1.6em;
        font-size: 1.8em;
        font-weight: 400;
      }
      // !Mobile
      @include mq-max() {
        h3{
          font-size: 1.4em;
          margin: 1.5em 0;
        }

      }
    }

    .content{
      .head{
        display: flex;
        // flex-wrap: wrap;
        &.r{
          flex-direction: row-reverse;
        }
        .image img{
          margin-left: -10px;
        }
        .body{
          padding: 2em;
        }
      }

      // !Mobile
      @include mq-max() {
        .head{
          display: inherit;
          .image{
            text-align: center;
          }
          .body{
            padding: 0;
          }
        }
      }

    }

    ul{
        margin: 0;
        padding: 0;
        position: relative;

        li{
            text-align: center;
            display: inline-block;
            margin: 14px 30px 0 0;
            // width: 90px;
            font-size: 0.9em;
            font-weight: bold;
            white-space: nowrap;

            img{
                border: 1px solid $hmd-color;
                border-radius: 50%;
                padding: 5px;
                width: 50px;
                display: block;
                margin: auto;
                background-color: #fff;
            }
        }

        // !Mobile
        @include mq-max(){
            transform: none;
            white-space: normal;
            display: flex;
            justify-content: space-around;
            &:before{
                background-color: transparent;
            }
              li{
                margin-right: 10px;
                font-size: 0.7em;
              }
        }
    }

</style>
